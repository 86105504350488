<script >
import vueFilePond from 'vue-filepond/dist/vue-filepond.min.js';
import 'filepond/dist/filepond.min.css';

const FilePond = vueFilePond(
);

export default {
    name: 'FileUploadInput',
    components: {
        FilePond,
    },
    props: {
        endpoint: String,
        submission_id: String,
        title: String,
        field_name: String,
        allowedTypes: String,
        allowMultiple: Boolean,
        initialFiles: Array,
        readonly: Boolean,
    },
    emits: [
        'updateFileCount',
    ],
    data() {
        // From the docs for remove: "This end point [...] can only be set to a custom function."
        //  Also, we want to reuse the endpoint for reverting files, but by default filepond would do the request
        //  differently when just specifying the URL. (Would put the file name in body instead of query string)
        const removeFunction = (fileId, onDone, onError) => {
            axios.delete(this.endpoint + '/remove?file=' + encodeURIComponent(fileId))
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        onError('Error deleting file');
                        return;
                    }
                    onDone();
                })
                .catch(() => {
                    onError('Error deleting file');
                });
        };

        return {
            file_object:null,
            collapsed: false,
            sending: false,
            destination_encoded: '',
            message: false,
            message_type: 'success',
            myFiles: this.initialFiles,
            fileServer: {
                url: this.endpoint,
                process: {
                    url: '/upload',
                    onload: (response) => JSON.parse(response).fileId,
                },
                fetch: null,
                load: '/load?file=',
                revert: removeFunction,
                remove: removeFunction,
                headers: {
                    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content,
                    'Accept': 'application/json',
                },
            },

        }
    },
    methods: {
        handleFilePondInit: function () {
            console.log("FilePond has initialized");

            // FilePond instance methods are available on `this.$refs.pond`
        },
        handleUpdateFiles: function (fileItems) {
            this.$emit('updateFileCount', (fileItems || []).length);
        },
        handleFileClick: function (fileItem) {
            if (!fileItem.serverId) {
                return; // still uploading
            }
            window.open(this.endpoint + '/load?file=' + encodeURIComponent(fileItem.serverId), '_blank');
        }
    }
}
</script>

<template>
    <div class="file-upload-input">
        <file-pond
            :disabled="readonly"
            :name="field_name"
            ref="pond"
            data-max-file-size="3MB"
            :allow-multiple="allowMultiple"
            :label-idle="readonly
                ? (myFiles.length > 0 ? 'Download files' : 'No files uploaded')
                : 'Drag files here...'
            "
            :accepted-file-types="allowedTypes"
            :server="fileServer"
            :files="myFiles"
            :init="handleFilePondInit"
            :allowRevert="!readonly"
            :allowRemove="!readonly"
            @updatefiles="handleUpdateFiles"
            @activatefile="handleFileClick"
        />
    </div>
</template>

<style>
.file-upload-input .filepond--file-info-main {
    cursor: pointer;
    pointer-events: auto;
}
.file-upload-input .filepond--file-info-main:hover {
    text-decoration: underline;
}
</style>
