<template>
    <div>
        <div class="row mt-4">
            <div class="col-md-3" v-for="f in filters" :key="f.name">
                <div class="form-group mb-3">
                    <label v-if="f.type !== 'checkbox'" :for="'filter-input-' + f.name">
                        {{ f.label }} <span v-if="f.required" class="text-danger">*</span>
                    </label>
                    <label v-else></label>

                    <v-select v-if="f.type === 'custom_select'"
                              :id="'filter-input-' + f.name"
                              v-model="filter[f.name]"
                              :options="f.options"
                              :multiple="f.multi"
                              :reduce="o => o.value"
                    />
                    <input v-if="f.type === 'text'"
                           :id="'filter-input-' + f.name"
                           v-model="filter[f.name]"
                           class="form-control"
                    >
                    <div class="form-check" v-if="f.type === 'checkbox'">
                        <input
                            :id="'filter-input-' + f.name"
                            v-model="filter[f.name]"
                            class="form-check-input"
                            type="checkbox"
                        >
                        <label class="form-check-label" :for="'filter-input-' + f.name">
                            {{ f.label }} <span v-if="f.required" class="text-danger">*</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="" v-if="reportRan">
            <div class="d-flex">
                <div class="flex-grow-1">
                </div>
                <div class="flex-shrink-0">

                    <button v-if="filters.length" class="btn btn-primary mr-4" @click="runReport(1)" :disabled="loading || requiredFiltersMissing">
                        Run Report
                    </button>

                    <a class="btn btn-primary" :href="downloadUrlFiltered">
                        Download
                    </a>
                </div>
            </div>

            <data-table
                :data="rows"
                :pagination="pagination"
                :query="query"
                :columns="columns"
                :loading="loading"
                :auto-layout="autoLayout"
                @updatePage="updatePage"
                @updateSort="updateSort"
                extra-table-class="no-vertical-border"
            >
                <template #data="{data}">
                    <tr v-for="(row) in data">
                        <td v-for="(column) in columns">
                            <template v-if="column.type === 'link' && row[column.urlField]">
                                <a :href="row[column.urlField]">{{ row[column.data] }}</a>
                            </template>
                            <template v-else-if="column.type === 'html'">
                                <span v-html="row[column.data]"></span>
                            </template>
                            <template v-else>
                                {{ row[column.data] }}
                            </template>
                        </td>
                    </tr>
                </template>
                <template #num-per-page>
                    <p class="form-group">
                        Show
                        <select id="admin-search-per-page" v-model="query.perPage"
                                class="form-select d-inline-block ms-2 w-auto p-0 pr-4 pl-2" @change="updatePerPage">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        entries per page
                    </p>
                </template>
            </data-table>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DataTable from "./DataTable.vue";
import DownloadIcon from "../Icons/download.vue";
import axios from "axios";
import format from "date-fns/format";
import parse from "date-fns/parse";
import _ from "lodash";

export default defineComponent({
    name: "TabularReport",

    components: {
        DataTable,
        DownloadIcon
    },

    props: {
        filters: {
            type: Array,
            required: true
        },
        fixedFilters: {
            type: Object,
            required: false,
            default: () => {
                return {};
            }
        },
        executeUrl: {
            type: String,
            required: true
        },
        downloadUrl: {
            type: String,
            required: true
        },
        autoLayout: {
            type: Boolean,
            required: false,
        }
    },

    data() {
        return {
            rows: [],
            columns: [],
            loading: false,
            reportRan: false,
            filter: { },
            query: {
                currentSort: null,
                currentSortDir: null,
                perPage: 20,
                currentPage: null,
            },
            pagination: {
                hasPrevPage: false,
                hasNextPage: false,
                lastPage: 1,
                from: 0,
                to: 0,
                total: 0,
            },
        }
    },

    computed: {
        downloadUrlFiltered() {
            return this.downloadUrl
                + '?'
                + new URLSearchParams({
                    ...this.buildFilterQueryParams(),
                    sort: this.query.currentSort ?? '',
                    direction: this.query.currentSortDir ?? '',
                }).toString();
        },

        requiredFiltersMissing() {
            return this.filters.filter((filter) => {
                if (!filter.required) {
                    return false;
                }
                if (filter.type === 'date_range') {
                    return !this.filter[filter.name + '_from'] || !this.filter[filter.name + '_to'];
                }
                return !this.filter[filter.name];
            }).length > 0;
        }
    },

    watch: {
        filter: {
            handler: function () {
                this.runReportDebounced();
            },
            deep: true,
        }
    },

    mounted() {
        this.setDefaults();
        this.setFiltersFromUrl();
        if (!this.requiredFiltersMissing) {
            this.runReportDebounced();
        }
    },

    methods: {
        setDefaults() {
            const urlParams = new URLSearchParams(window.location.search);
            const urlHasFilters = urlParams.get('_f') === '1';

            for (let filter of this.filters) {
                if (urlHasFilters && filter.syncUrl) {
                    // don't set defaults if there are filters in the url and this filter can be set via url
                    // to avoid setting defaults for filters that were explicitly cleared by the user
                    continue;
                }
                if (filter.default) {
                    switch (filter.type) {
                        case 'date_range':
                            if (filter.default.from) {
                                this.filter[filter.name + '_from'] = parse(filter.default.from, 'yyyy-MM-dd', new Date());
                            }
                            if (filter.default.to) {
                                this.filter[filter.name + '_to'] = parse(filter.default.to, 'yyyy-MM-dd', new Date());
                            }
                            break;
                        default:
                            this.filter[filter.name] = filter.default;
                    }
                }
            }
        },

        setFiltersFromUrl() {
            const urlParams = new URLSearchParams(window.location.search);

            for (let filter of this.filters) {
                if (!filter.syncUrl) {
                    continue;
                }

                if(filter.multi) {
                    if (urlParams.has(`${filter.name}[]`)) {
                        this.filter[filter.name] = urlParams.getAll(`${filter.name}[]`);
                    }
                } else if (filter.type === 'date_range') {
                    if (urlParams.has(filter.name + '_from')) {
                        this.filter[filter.name + '_from'] = parse(urlParams.get(filter.name + '_from'), 'yyyy-MM-dd', new Date());
                    }
                    if (urlParams.has(filter.name + '_to')) {
                        this.filter[filter.name + '_to'] = parse(urlParams.get(filter.name + '_to'), 'yyyy-MM-dd', new Date());
                    }
                } else if (['date_year', 'date_month', 'date_day'].includes(filter.type)) {
                    if (urlParams.has(filter.name)) {
                        let filterFormat = 'yyyy-MM-dd';
                        switch (filter.type) {
                            case 'date_year':
                                filterFormat = 'yyyy';
                                break;
                            case 'date_month':
                                filterFormat = 'yyyy-MM';
                                break;
                        }

                        this.filter[filter.name] = parse(urlParams.get(filter.name), filterFormat, new Date());
                    }
                } else if (urlParams.has(filter.name)) {
                    this.filter[filter.name] = urlParams.get(filter.name);
                }
            }
        },

        updateUrl() {
            const urlParams = new URLSearchParams(window.location.search);

            for (let filter of this.filters) {
                if (!filter.syncUrl) {
                    continue;
                }

                if(filter.multi) {
                    urlParams.delete(`${filter.name}[]`);
                    for(const value of this.filter[filter.name] ?? []) {
                        urlParams.append(`${filter.name}[]`, value);
                    }

                } else if(filter.type === 'date_range') {
                    for(const suffix of ['_from', '_to']) {
                        if (this.filter[filter.name + suffix]) {
                            urlParams.set(filter.name + suffix, format(new Date(this.filter[filter.name + suffix]), 'yyyy-MM-dd'));
                        } else {
                            urlParams.delete(filter.name + suffix);
                        }
                    }

                } else if(['date_year', 'date_month', 'date_day'].includes(filter.type)) {
                    let filterFormat = 'yyyy-MM-dd';
                    switch (filter.type) {
                        case 'date_year':
                            filterFormat = 'yyyy';
                            break;
                        case 'date_month':
                            filterFormat = 'yyyy-MM';
                            break;
                    }

                    if (this.filter[filter.name]) {
                        urlParams.set(filter.name, format(new Date(this.filter[filter.name]), filterFormat));
                    } else {
                        urlParams.delete(filter.name);
                    }

                } else if(this.filter[filter.name]) {
                    urlParams.set(filter.name, this.filter[filter.name]);

                } else {
                    urlParams.delete(filter.name);
                }
            }

            let url = `${window.location.origin}${window.location.pathname}`;
            if(Array.from(urlParams.keys()).length > 0) {
                urlParams.set('_f', '1');
                url += `?${urlParams.toString()}`;
            }

            window.history.replaceState({}, '', url);
        },

        runReportDebounced: _.debounce(function () {
            this.runReport();
        }, 500),

        runReport(page = 1) {
            this.updateUrl();
            if (this.requiredFiltersMissing) {
                this.loading = false;
                this.reportRan = false;
                return;
            }

            this.loading = true;
            this.reportRan = true;
            axios.get(this.executeUrl, {
                params: {
                    ...this.buildFilterQueryParams(),
                    page: page,
                    per_page: this.query.perPage,
                    sort: this.query.currentSort,
                    direction: this.query.currentSortDir,
                }
            }).then((response) => {
                this.columns = response.data.columns;

                this.rows = response.data.page.data;
                this.query.currentPage = response.data.page.current_page;
                this.pagination.from = response.data.page.from;
                this.pagination.to = response.data.page.to;
                this.pagination.total = response.data.page.total;
                this.pagination.hasPrevPage = !!response.data.page.prev_page_url;
                this.pagination.hasNextPage = !!response.data.page.next_page_url;
                this.pagination.lastPage = response.data.page.last_page;

                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                this.$root.$refs.flash_alert.activateFlashAlert('Error executing report', 'error');
                console.error(error);
            })
        },

        buildFilterQueryParams() {
            const params = {};
            for (const filter of this.filters) {
                if (filter.type === 'date_range') {
                    for(const suffix of ['_from', '_to']) {
                        params[filter.name + suffix] = this.filter[filter.name + suffix]
                            ? format(new Date(this.filter[filter.name + suffix]), 'yyyy-MM-dd')
                            : undefined;
                    }
                    continue;
                } else if (filter.type === 'date_year') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy')
                        : undefined;
                    continue;
                } else if (filter.type === 'date_month') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy-MM')
                        : undefined;
                    continue;
                } else if (filter.type === 'date_day') {
                    params[filter.name] = this.filter[filter.name]
                        ? format(new Date(this.filter[filter.name]), 'yyyy-MM-dd')
                        : undefined;
                    continue;
                }

                if (Array.isArray(this.filter[filter.name])) {
                    params[filter.name] = this.filter[filter.name].join(',');
                } else {
                    params[filter.name] = this.filter[filter.name];
                }
            }

            // add fixedFilters
            for (const [key, value] of Object.entries(this.fixedFilters)) {
                params[key] = value;
            }

            // remove empty values
            Object.keys(params)
                .forEach(key => {
                    if (typeof params[key] === 'undefined' || params[key] === null) {
                        delete params[key];
                    }
                });

            return params;
        },

        updatePerPage() {
            this.runReport();
        },

        updatePage(n) {
            this.runReport(n);
        },

        updateSort(column, direction) {
            this.query.currentSort = column;
            this.query.currentSortDir = direction;
            this.runReport();
        },
    }
});
</script>
