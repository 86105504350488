<template>
    <div class="d-inline-block">
        <div class="select">
            <select id="entry-status-select" class="plain-select" v-model="status_to_update" @change="changeStatus()">
                <option value="" v-if="includeBlank"></option>
                <option :selected="order_status" v-for="(value, key) in statuses" :value="value" v-text="value"></option>
            </select>
        </div>

    </div>
</template>

<script>

    export default {
        props: {
            orderStatus: {
                type: String,
                required: true
            },
            actionUrl: {
                type: String,
                required: true
            },
            statuses: {
                type: Array,
                required: true
            },
            fieldName: {
                type: String,
                required: true
            },
            includeBlank: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                status_to_update: this.orderStatus
            }
        },
        methods: {
            changeStatus: function() {
                let that = this;
                let data = {
                    name: that.fieldName,
                    value: that.status_to_update
                };
                window.axios
                    .put(this.actionUrl, data)
                    .then((response) => {
                        let dataBack = response.data;
                        location.reload();
                    });
            },
        }
    }

</script>
