<template>
    <div class="col-12">
        <div v-if="errors">
            {{ errors }}
        </div>

        <div class="row">
            <div class="form-search-wrap col-12">
                <div class="choices-filter-group double position-relative">
                    <input type="text" name="keyword" id="keyword" placeholder="Search" v-model="query.keyword" >
                    <label class="search-icon-placement no-border" for="keyword">
                        <search-icon class="svg-accent"></search-icon>
                    </label>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-3 pt-2">
                <div class="form-floating mb-3">
                    <MultiSelectInput id="submission-search-judging-status-filer"
                                      :options="judgingStatusOptions"
                                      placeholder="Judging Status"
                                      :reduce="option => option.value"
                                      :model-value="query.judgingStatus"
                                      @update:modelValue="query.judgingStatus = $event.length ? $event : []"
                    />
                </div>
            </div>
            <div class="col-9 text-md-right">
                <a class="clear-button no-border cursor-pointer ml-3" @click.prevent="clearSearch"><close-icon class="svg-16 svg-accent"></close-icon> Clear Search</a>
            </div>
        </div>

        <data-table
            :columns="columns"
            :loading="loading"
            :data="submissions"
            :pagination="pagination"
            :query="query"
            @updatePage="updatePage"
            @updateSort="updateSort"
            :autoLayout="true"
        >
            <template #data="{data}">
                <tr v-for="submission in data" :key="submission.id" class="cursor-pointer"> <!-- TODO row clicking -->
                    <td class="pl-2"><a :href="submission.url">{{ submission.id }}</a></td>
                    <td>{{ submission.submitter_name }}</td>
                    <td>{{ submission.field_engineering_development }}</td>
                    <td>{{ submission.field_company_name }}</td>
                    <td>{{ submission.status }}</td>
                    <td>{{ submission.judging_status }}</td>
                    <td class="text-right pr-2">
                        <a :href="submission.url">View</a>
                    </td>
                </tr>
            </template>

            <template #num-per-page>
                <p class="form-group">
                    Show
                    <select id="admin-search-per-page" v-model="query.perPage"
                            class="form-select d-inline-block ms-2 w-auto p-0 pr-4 pl-2" @change="updatePerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    entries per page
                </p>
            </template>
        </data-table>

    </div>
</template>

<script >
import CloseIcon from "@/Icons/close.vue";
import DownChevronIcon from "@/Icons/down_chevron.vue";
import UpChevronIcon from "@/Icons/up-chevron.vue";
import SearchIcon from "@/Icons/search.vue";
import CalendarIcon from "@/Icons/calendar.vue";
import MultiSelectInput from "./MultiSelectInput.vue";
import _ from "lodash";

export default {
    name: 'AdminSearch',
    components: {MultiSelectInput, CalendarIcon, SearchIcon, UpChevronIcon, DownChevronIcon, CloseIcon},
    props: {
        searchApiUrl: {
            type: String,
            default: ''
        },
        judgingStatusOptions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        const queryParams = new URLSearchParams(window.location.search);

        return {
            loading: true,
            errors: '',
            submissions: [],
            columns: [
                { data: "id", label: "Entry #", sortable: "numeric" },
                { data: "submitter_name", label: "Submitter Name", sortable: "alpha" },
                { data: "field_engineering_development", label: "Development", sortable: "alpha" },
                { data: "field_company_name", label: "Company", sortable: "alpha" },
                { data: "status", label: "Entry Status", sortable: "alpha" },
                { data: "judging_status", label: "Judging Status", sortable: "alpha" },
                { data: "actions", label: "", class: "text-right"},
            ],
            createModalOpen: false,
            query: {
                currentSort: queryParams.get('sort') || 'name',
                currentSortDir: queryParams.get('direction') || 'asc',
                perPage: +queryParams.get('per_page') || 10,
                currentPage: +queryParams.get('page') || 1,

                keyword: queryParams.get('keyword') || '',
                judgingStatus: queryParams.get('judgingStatus') ? queryParams.get('judgingStatus').split(';') : [],
            },
            pagination: {
                hasPrevPage: false,
                hasNextPage: false,
                lastPage: 1,
                from: 0,
                to: 0,
                total: 0,
            },
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        buildQueryParams() {
            return {
                page: '' + this.query.currentPage,
                per_page: '' + this.query.perPage,
                sort: this.query.currentSort,
                direction: this.query.currentSortDir,

                keyword: this.query.keyword,
                judgingStatus: (this.query.judgingStatus || []).join(';'),
            };
        },
        load() {
            const queryParams = this.buildQueryParams();
            const queryString = new URLSearchParams(queryParams).toString();
            history.replaceState({}, '', `${window.location.origin}${window.location.pathname}?${queryString}`);

            this.loading = true;
            this.errors = '';

            axios.get(`${this.searchApiUrl}?${queryString}`).then(response => {
                this.submissions = response.data.data;
                this.pagination.from = response.data.from;
                this.pagination.to = response.data.to;
                this.pagination.total = response.data.total;
                this.pagination.hasPrevPage = !!response.data.prev_page_url;
                this.pagination.hasNextPage = !!response.data.next_page_url;
                this.pagination.lastPage = response.data.last_page;
                this.loading = false;

                if (this.pagination.lastPage < this.query.currentPage) {
                    this.query.currentPage = this.pagination.lastPage;
                    this.load();
                }
            }).catch(err => {
                console.error(err);

                let errorMessage;
                if (err.response && err.response.data && err.response.data.message) {
                    errorMessage = err.response.data.message;
                } else if (err.response && err.response.status) {
                    errorMessage = err.response.status + ' status error';
                } else {
                    errorMessage = 'Connection error';
                }

                this.errors = errorMessage;
                this.loading = false;
            })

        },

        updatePage(n) {
            this.query.currentPage = n;
            this.load();
        },
        updateSort(column, direction) {
            this.query.currentSort = column;
            this.query.currentSortDir = direction;
            this.load();
        },
        updatePerPage() {
            this.load();
        },
        clearSearch() {
            this.query.keyword = '';
            this.query.judgingStatus = [];
        },
        updateQuery() {
            this.query.currentPage = 1;
            this.load();
        },
        updateQueryDebounced: _.debounce(function () {
            this.updateQuery();
        }, 300),
    },
    watch: {
        'query.keyword': function (val) {
            this.updateQueryDebounced();
        },
        'query.judgingStatus': function (val) {
            this.updateQueryDebounced();
        },
    }
}
</script>

<style scoped>

</style>
