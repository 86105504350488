<template>
    <div>
        <div class="entry-info_item">
            <span class="label small-caps">{{ label }}</span>
            <span>{{ fieldValue.map(id => judges.find(judge => judge.code === id).label).join(', ') }}</span>

            <span v-if="isAdmin"> - </span>
            <a v-if="isAdmin" href="" @click.prevent="showEditingModal = true">Edit</a>

            <modal v-if="showEditingModal" @close="showEditingModal = false" modalclass="wide">
                <h3 slot="header">
                    Assign Judges
                </h3>
                <div class="content" slot="body">
                    <v-select
                        multiple
                        id="fieldName"
                        :placeholder="placeholder"
                        v-model="fieldValue"
                        :text="label"
                        :reduce="option => option.code"
                        :options="judges">
                    </v-select>
                </div>
                <div class="form-group-submit d-flex justify-content-between" slot="footer">
                    <button class="btn-black" @click="showEditingModal = false">Cancel</button>
                    <a href="" @click.prevent="applyChange" class="btn btn-primary">Save</a>
                </div>
            </modal>
        </div>
    </div>
</template>

<script>

import MultiSelectInput from "@/components/MultiSelectInput.vue";

export default {
    components: {MultiSelectInput},
    props: {
        id: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        fieldName: {
            type: String,
            required: true
        },
        fieldValue: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: true
        },
        actionUrl: {
            type: String,
            required: true
        },
        judges: {
            type: Array,
            required: true
        },
        isAdmin: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showEditingModal: false
        }
    },
    methods: {
        applyChange: function() {
            let that = this;
            let data = {
                id: that.id,
            };
            data[that.fieldName] = that.fieldValue;

            window.axios({
                method: 'put',
                url: that.actionUrl,
                data: data,
            }).then(function(response) {
                window.location.reload();
            }).catch(function (error) {
                // handle error
                console.log('This is the error returned', error);
            });
        },
    }
}
</script>
