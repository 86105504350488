<script>
import PlusCircleIcon from "../../Icons/plus-circle.vue";

export default {
    name: "SubmissionJudgingNoteItem",

    components: {
        PlusCircleIcon,
    },

    props: {
        note: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            addCommentOpen: false,
            newComment: '',
            commentSubmitting: false,
            commentError: null,

            editing: false,
            editText: '',
            editSubmitting: false,
            editError: null,
        }
    },

    mounted() {
    },

    methods: {
        addComment() {
            this.commentSubmitting = true;
            this.error = null;
            axios.post(this.note.comment_url, {content: this.newComment})
                .then(response => {
                    this.newComment = '';
                    this.addCommentOpen = false;
                    this.$emit('comment-added');
                })
                .catch(error => {
                    this.error = 'Error saving note';
                })
                .finally(() => {
                    this.commentSubmitting = false;
                });
        },
        startEdit() {
            this.editing = true;
            this.editText = this.note.content;
        },
        submitEdit() {
            this.editError = null;
            axios.put(this.note.edit_url, {content: this.editText})
                .then(response => {
                    this.editing = false;
                    this.$emit('comment-edited');
                })
                .catch(error => {
                    this.editError = 'Error saving changes';
                });
        },
    }
}
</script>

<template>
    <div class="note">
        <div class="note-meta">
            <span class="note-author">{{ note.author_name }}</span>,
            <span class="note-date">{{ $formatDateTime(note.created_at) }}</span>
            <span class="note-edited-hint" v-if="note.is_edited"> - last edited at {{ $formatDateTime(note.updated_at) }}</span>
        </div>
        <div class="note-content" v-if="!editing">{{ note.content }}</div>
        <form @submit.prevent="submitEdit" v-else>
            <div class="form-group">
                <textarea
                    maxlength="10000"
                    v-model="editText"
                    class="form-control"
                    rows="3"></textarea>
                <p class="error-text" v-if="editError">{{ editError }}</p>
            </div>
            <div>
                <button type="button" class="btn-secondary" @click="editing = false">Cancel</button>
                <button type="submit" class="btn-yellow  ml-2" :disabled="editSubmitting || !editText.length">
                    <span v-if="editSubmitting">Submitting...</span>
                    <span v-else>Save Changes</span>
                </button>
            </div>
        </form>


        <div class="note-actions">
            <a href="#" @click.prevent="startEdit" v-if="note.can_edit">Edit</a>
            <a href="#" @click.prevent="addCommentOpen = true" v-if="note.can_comment">Add Comment</a>
        </div>

        <div v-if="addCommentOpen" class="comment-add-section">
            <form class="form" @submit.prevent="addComment">
                <div class="form-group">
                    <label class="form-label">Write a comment:</label>
                    <textarea maxlength="10000" v-model="newComment" class="form-control" rows="3"></textarea>
                    <p class="error-text" v-if="commentError">{{ commentError }}</p>
                </div>
                <div>
                    <button type="button" class="btn-secondary" @click="addCommentOpen = false">Cancel</button>
                    <button type="submit" class="btn-yellow  ml-2" :disabled="commentSubmitting || !newComment.length">
                        <span v-if="commentSubmitting">Submitting...</span>
                        <span v-else>Add Comment</span>
                    </button>
                </div>
            </form>
        </div>

        <div class="comments">
            <SubmissionJudgingNoteItem
                v-for="comment in note.comments"
                :key="comment.id"
                :note="comment"
                @comment-added="$emit('comment-added')"
                @comment-edited="$emit('comment-edited')"
            />
        </div>
    </div>
</template>

<style scoped>

.comment-add-section {
    max-width: 600px;
}
.note {
    border: 1px solid #DCDCDC;
    padding: 1rem;
    margin-top: 1rem;
}
.note-meta {
    font-size: 0.9rem;
    color: #666;
}
.note-author {
    font-weight: bold;
}
.note-edited-hint {
    font-size: 0.8rem;
    color: #999;
}
.note-content {
    margin-top: 0.5rem;
    white-space: pre-wrap;
}
.note-actions {
    margin-top: 0.5rem;
}
.note-actions a {
    font-size: 0.9rem;
    margin-right: .8rem;
}

</style>
