export default {
    install: (Vue) => {
        Vue.prototype.$formatDate = (date) => {
            if (!date) {
                return '';
            }

            return new Date(date).toLocaleString('en-us', { month: '2-digit' })
                + '-'
                + new Date(date).toLocaleString('en-us', { day: '2-digit' })
                + '-'
                + new Date(date).toLocaleString('en-us', { year: 'numeric' });
        }

        Vue.prototype.$formatDateTime = (date) => {
            if (!date) {
                return '';
            }

            return Vue.prototype.$formatDate(date)
                + ' '
                + new Date(date).toLocaleString('en-us', { hour: '2-digit', minute: '2-digit', hour12: true });
        }
    }
}
