<template>
    <div>
        <div class="entry-info_item">
            <span class="label small-caps">Internal Notes:</span>
            <div class="value internal-text-value" style="text-transform: none" v-if="!wysiwygOpen"><span v-html="originalInternalNote"></span> <a href="#" @click.prevent="wysiwygOpen = !wysiwygOpen" v-text="editOrAdd"></a></div>
        </div>
        <div class="editor" v-show="wysiwygOpen">
            <wysiwyg-input :model-value="internalNote" @update-model-value="(value) => internalNote = value" />
            <div class="edit-buttons mt-2">
                <button class="btn save" @click="saveInternalNote"><save-icon class="svg-black"></save-icon> Save</button>
                <button class="btn btn-default btn-grey cancel ml-2" @click="closeInternalNote">Cancel</button>
            </div>
            <a href=""></a>
        </div>
    </div>
</template>

<script>
    import WysiwygInput from './Form/WysiwygInput.vue';
    import SaveIcon from "@/Icons/save.vue";

    export default {
        components: {
            SaveIcon,
            WysiwygInput,
        },

        props: {
            actionUrl: {
                type: String,
                required: true,
            },
            internalNoteLoaded: {
                type: String,
                required: true,
            },
            id: {
                type: Number,
                required: true,
            }
        },

        data() {
            return {
                internalNote: '',
                editOrAdd: 'Add',
                wysiwygOpen: false,
                originalInternalNote: '',
            }
        },

        created: function() {
            this.originalInternalNote = this.internalNoteLoaded;
            this.internalNote = this.internalNoteLoaded;
            if (this.originalInternalNote?.length > 0) {
                this.editOrAdd = 'Edit';
            }
        },

        watch: {
            wysiwygOpen: function (val) {
                if(val) {
                    this.editOrAdd = 'Close';
                } else {
                    if(this.originalInternalNote.length > 0) {
                        this.editOrAdd = 'Edit';
                    } else {
                        this.editOrAdd = 'Add';
                    }
                }
            },
        },

        methods: {
            saveInternalNote: function() {
                let that = this;
                window.axios({
                    method: 'put',
                    url: this.actionUrl,
                    data: {
                        id: this.id,
                        notes: that.internalNote
                    }
                }).then(function(response) {
                    that.originalInternalNote = that.internalNote;
                    that.wysiwygOpen = false;
                    window.location.reload();
                }).catch(function (error) {
                    console.log('This is the error returned', error);
                });
            },
            closeInternalNote: function() {
                this.wysiwygOpen = false;
                this.internalNote = this.originalInternalNote;
            }
        },
    }
</script>
