<template>
    <v-select
        :id="id"
        v-model="value"
        :options="options"
        :class="selectClasses"
        multiple
        deselect-from-dropdown
        :placeholder="placeholder"
        :close-on-select="false"
        :autoscroll="usingKeyboardNavigation"
        :map-keydown="mapKeydown"
        @mousemove="onMouseMove"
        @search:blur="() => usingKeyboardNavigation = false"
        v-bind="$attrs"
    >
        <template #option="{ full_name, label }">
            {{ full_name ?? label }}
        </template>
        <template v-if="showSelectAll" #list-footer>
            <li class="vs__dropdown-list-footer px-3 py-2 mb-0 sticky gap-3">
                <a href="#" @click.prevent="value = options" tabindex="-1" :class="(value ?? []).length !== options.length ? 'small-uppercase-blue' : 'small-uppercase-grey'">Select all</a>
                <a href="#" @click.prevent="value = null" tabindex="-1" :class="(value ?? []).length !== 0 ? 'small-uppercase-blue' : 'small-uppercase-grey'">Clear</a>
            </li>
        </template>
    </v-select>
</template>

<script lang="ts">
export default {
    name: "MultiSelectInput",

    props: {
        id: {
            type: String,
            required: true,
        },
        modelValue: {
            type: Array,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        showSelectAll: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String
        }
    },

    data() {
        return {
            usingKeyboardNavigation: false,
            previousCursor: {
                clientX: null,
                clientY: null,
            },
        };
    },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            },
        },
        selectClasses() {
            return {
                'vs-multi-select-input': true,
                'vs--using-keyboard': this.usingKeyboardNavigation,
                'vs--dark': this.dark,
            };
        },
    },

    methods: {
        // Only reset `usingKeyboardNavigation` to `false` if the cursor has actually moved.
        // Otherwise, `onMouseMove` was triggered by the mouse still being over the
        // element while it was auto scrolling during keyboard navigation.
        onMouseMove(event) {
            // Don't trigger the first time the mouse moves. This allows us to save the
            // mouse position on first keydown, and if someone is actually moving
            // the mouse then `onMouseOver` will be re-triggered momentarily.
            if (this.previousCursor.clientX === null && this.previousCursor.clientY === null) {
                this.previousCursor = { clientX: event.clientX, clientY: event.clientY };
                return;
            }

            if (event.clientX === this.previousCursor.clientX && event.clientY === this.previousCursor.clientY) {
                return;
            }

            // Cursor has moved, reset keyboard navigation
            this.usingKeyboardNavigation = false;
        },
        mapKeydown(defaultMap, vm) {
            return {
                ...defaultMap,

                // up
                38: (event) => {
                    // reset cursor calculation
                    this.previousCursor = { clientX: null, clientY: null };
                    this.usingKeyboardNavigation = true;
                    return defaultMap[38](event);
                },
                // down
                40: (event) => {
                    // reset cursor calculation
                    this.previousCursor = { clientX: null, clientY: null };
                    this.usingKeyboardNavigation = true;
                    return defaultMap[40](event);
                },
            };
        },
    }
}
</script>

<style scoped>
>>> {
    --vs-dropdown-option--deselect-bg: #f1ad14;
    --vs-dropdown-option--deselect-color: #000000;
}
</style>
