<script>
import PlusCircleIcon from "../../Icons/plus-circle.vue";
import SubmissionJudgingNoteItem from "./SubmissionJudgingNoteItem.vue";

export default {
    name: "SubmissionJudgingNotes",

    components: {
        SubmissionJudgingNoteItem,
        PlusCircleIcon,
    },

    props: {
        listUrl: {
            type: String,
            required: true
        },
        canCreate: {
            type: Boolean,
            required: true
        },
    },

    data() {
        return {
            notes: [],
            loading: true,
            error: null,

            canCreate: false,
            createUrl: '',

            addNoteOpen: false,
            newNote: '',
            noteSubmitting: false,
        }
    },

    mounted() {
        this.fetchNotes();
    },

    methods: {
        fetchNotes() {
            axios.get(this.listUrl)
                .then(response => {
                    this.notes = response.data.notes;
                    this.canCreate = response.data.can_create;
                    this.createUrl = response.data.create_url;
                })
                .catch(error => {
                    this.error = 'Error loading notes';
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        addNote() {
            this.noteSubmitting = true;
            axios.post(this.createUrl, {content: this.newNote})
                .then(response => {
                    this.newNote = '';
                    this.addNoteOpen = false;
                    this.fetchNotes();
                })
                .catch(error => {
                    this.error = 'Error adding note';
                })
                .finally(() => {
                    this.noteSubmitting = false;
                });
        }
    }
}
</script>

<template>
    <div class="judge-comment-section pb-4">
        <div class="d-flex">
            <h2 class="flex-grow-1">Judging Notes</h2>
            <div>
                <button @click="addNoteOpen = true" v-if="canCreate">
                    <plus-circle-icon class="svg-black mr-2"></plus-circle-icon>
                    Add Note
                </button>
            </div>
        </div>

        <div v-if="error" class="error-text p-4">{{ error }}</div>


        <div v-if="addNoteOpen" class="comment-add-section">
            <form class="form" @submit.prevent="addNote">
                <div class="form-group">
                    <label class="form-label">Write a note:</label>
                    <textarea maxlength="10000" v-model="newNote" class="form-control" rows="3"></textarea>
                </div>
                <div >
                    <button type="button" class="btn-secondary" @click="addNoteOpen = false">Cancel</button>
                    <button type="submit" class="btn-yellow  ml-2" :disabled="noteSubmitting || !newNote.length">
                        <span v-if="noteSubmitting">Submitting...</span>
                        <span v-else>Add Note</span>
                    </button>
                </div>
            </form>
        </div>

        <div v-if="loading">
            Loading...
            <div class="spinner small"></div>
        </div>

        <div v-else>
            <div v-if="notes.length === 0" class="text-center">
                <em>No notes yet</em>
            </div>
            <div class="notes-list" v-else>
                <SubmissionJudgingNoteItem
                    :note="note"
                    v-for="note in notes"
                    :key="note.id"
                    @comment-added="fetchNotes"
                    @comment-edited="fetchNotes"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>
.judge-comment-section {
    border-bottom: 1px solid #DCDCDC;
}

.comment-add-section {
    max-width: 600px;
}

.notes-list {
    margin-top: 1rem;
}

.note {
    border: 1px solid #DCDCDC;
    padding: 1rem;
    margin-top: 1rem;
}

.note-meta {
    font-size: 0.9rem;
    color: #666;
}

.note-author {
    font-weight: bold;
}

.note-content {
    margin-top: 0.5rem;
}

</style>
